import { systemHooks } from './systemHooks';
import { ExecutionType } from './types';
import type { BlockingIterative, Plugin } from './types';

class HookManager {
	private plugins: Plugin[] = [];

	registerPlugin(plugin: Plugin) {
		this.plugins.push(plugin);
		plugin
			.init()
			.catch((err) => console.error(`Wingback: Error initializing plugin ${plugin.name}:`, err));
	}

	registerPlugins(plugins: Plugin[]) {
		this.plugins.push(...plugins);

		// Initialize all plugins in parallel
		Promise.all(
			plugins.map((plugin) =>
				plugin
					.init()
					.catch((err) => console.error(`Wingback: Error initializing plugin ${plugin.name}:`, err))
			)
		).catch((err) => console.error('Wingback: Error initializing plugins:', err));
	}

	async executeHook<K extends keyof typeof systemHooks>(
		hookName: K,
		args: Parameters<typeof systemHooks[K]['execute']>[0]
	): Promise<ReturnType<typeof systemHooks[K]['execute']>> {
		const hook = systemHooks[hookName];
		const pluginHooks = this.plugins
			.filter((p) => p.hooks && p.hooks[hookName])
			.map((p) => p.hooks![hookName]!);

		switch (hook.executionType) {
			case ExecutionType.NonBlocking:
				// Fire-and-forget, no return value
				pluginHooks.forEach((h) => {
					Promise.resolve(h(args)).catch((e) => console.error(e));
				});
				return undefined as any;

			case ExecutionType.BlockingIterative:
				// Sequential execution with result passing
				let result: any;
				const blockingHook = hook as BlockingIterative<any, any>;

				if (pluginHooks.length === 0) {
					// No hooks registered, use defaultFn
					result = blockingHook.defaultFn(args);
				} else {
					// Execute the first hook with args or initialValue
					result = blockingHook.initialValue !== undefined ? blockingHook.initialValue : args;
					for (const pluginHook of pluginHooks) {
						result = await pluginHook(result);
					}
				}
				return result;

			case ExecutionType.BlockingParallel:
				// Execute all in parallel and return an array of results
				return Promise.all(pluginHooks.map((h) => h(args))) as any;

			default:
				throw new Error(`Unknown execution type:`);
		}
	}
}

export default new HookManager();
