import type { Currency, Cycle, Plan, Pricing, Units } from '$lib/types';

import { PUBLIC_PRICING_ENDPOINT } from '$lib/constants';

import { http } from '$lib/api';

export async function get(slugOrId: string) {
	return await http.get<Pricing>(`${PUBLIC_PRICING_ENDPOINT}/${slugOrId}`);
}

// signup estimate: /ss/contract/cost_estimate
type Estimate = {
	cycle_start_charges: number;
	minimum_usage: number;
	extra_cycle_end_charges: number;
};

export type SignupEstimate = {
	recurring_price: Estimate; // everyCycle subtotal - without discounts
	one_time_price: Estimate; // oneTime subtotal - without discounts
	// following data is not being used, so far
	on_demand_price: Estimate;
	custom_cycle_charges: {
		periodic_daily: Estimate;
		periodic_weekly: Estimate;
		periodic_monthly: Estimate;
		periodic_quarterly: Estimate;
		periodic_yearly: Estimate;
	};
};

type SignupEstimateParams = {
	currency: Currency;
	cycle: Cycle;
	plan_id: Plan['id'];
	unit_count_configuration?: Units;
};

// /ss/contract/cost_estimate
export async function signupEstimate(slugOrId: string, params: SignupEstimateParams) {
	return await http.get<SignupEstimate>(`${PUBLIC_PRICING_ENDPOINT}/${slugOrId}/cost_estimate`, {
		method: 'POST',
		body: JSON.stringify(params),
	});
}
