import { pluginTriggers } from './pluginTriggers';
import { ExecutionType } from './types';
import type { BlockingIterative } from './types';

class TriggerManager {
	private triggers: { [K in keyof typeof pluginTriggers]: Function[] } = {} as any;

	register<K extends keyof typeof pluginTriggers>(
		triggerName: K,
		callback: (
			args: Parameters<typeof pluginTriggers[K]['execute']>[0]
		) => ReturnType<typeof pluginTriggers[K]['execute']>
	) {
		if (!this.triggers[triggerName]) {
			this.triggers[triggerName] = [];
		}
		this.triggers[triggerName].push(callback);
		return {
			unregister: () => {
				const index = this.triggers[triggerName].indexOf(callback);
				if (index !== -1) {
					this.triggers[triggerName].splice(index, 1);
				}
			},
		};
	}

	async executeTrigger<K extends keyof typeof pluginTriggers>(
		triggerName: K,
		args: Parameters<typeof pluginTriggers[K]['execute']>[0]
	): Promise<ReturnType<typeof pluginTriggers[K]['execute']>> {
		const trigger = pluginTriggers[triggerName];
		const callbacks = this.triggers[triggerName] || [];

		switch (trigger.executionType) {
			case ExecutionType.NonBlocking:
				callbacks.forEach((cb) => {
					Promise.resolve(cb(args)).catch((e) => console.error(e));
				});
				return undefined as any;

			case ExecutionType.BlockingIterative:
				let result: any;
				const blockingTrigger = trigger as BlockingIterative<any, any>;

				if (callbacks.length === 0) {
					result = blockingTrigger.defaultFn(args);
				} else {
					result = blockingTrigger.initialValue !== undefined ? blockingTrigger.initialValue : args;
					for (const callback of callbacks) {
						result = await callback(result);
					}
				}
				return result;

			case ExecutionType.BlockingParallel:
				return Promise.all(callbacks.map((cb) => cb(args))) as any;

			default:
				throw new Error(`Unknown execution type`);
		}
	}
}

export default new TriggerManager();
