import { type HookDefinition, blockingIterative, blockingParallel, nonBlocking } from './types';

export const pluginTriggers: { [key: string]: HookDefinition<any, any> } = {
	redrawPricingPages: nonBlocking<void>(),

	getAllPricingSlugs: blockingParallel<void, string>(),

	sumCost: blockingIterative<
		{ total: number; currency: string },
		{ total: number; currency: string }
	>((cost) => cost),
};
