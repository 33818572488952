type ReadyCallback = () => void;

// for future use if we ever need to intialize more stuff on load
let isReady = false;
const callbacks: ReadyCallback[] = [];

// Executes the callback when Wingback is ready.
export function ready(callback: ReadyCallback): void {
	if (isReady) {
		callback();
	} else {
		callbacks.push(callback);
	}
}

export function markAsReady(): void {
	isReady = true;
	let callback;
	while ((callback = callbacks.pop())) {
		try {
			callback();
		} catch (error) {
			console.error('Wingback: Error executing ready callback:', error);
		}
	}
}
