import _Wingback from '$lib/wingback';

import { markAsReady } from './lib/modules/ready';

function processQueue(queue: Array<[string, IArguments]>) {
	queue.forEach(([method, args]) => {
		const parts = method.split('.');
		let current: any = _Wingback;

		for (let i = 0; i < parts.length; i++) {
			if (i === parts.length - 1) {
				if (typeof current[parts[i]] === 'function') {
					current[parts[i]].apply(current, args);
				} else {
					console.warn(`Wingback: Method ${method} is not a function`);
				}
			} else {
				current = current[parts[i]];
				if (!current) {
					console.warn(`Wingback: ${parts.slice(0, i + 1).join('.')} is not defined`);
					return;
				}
			}
		}
	});
}

// Process any queued calls from before Wingback was fully initilaized
if (typeof window !== 'undefined') {
	if ((window as any)?.Wingback?.__q) {
		processQueue((window as any).Wingback.__q);
	}
	if ((window as any)?.Wingback) {
		(window as any).Wingback = _Wingback;
	}
}

// mark wingback as fully loaded/ready
markAsReady();

export default _Wingback;
