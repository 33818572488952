<script lang="ts">
	import type { Currency, Cycle, Feature, Plan, PricingOptions, Tier, Units } from '$lib/types';

	import { onDestroy } from 'svelte';

	import {
		CYCLES,
		DEFAULT_DECIMALS,
		DEFAULT_PRICING_CALLBACK_URL,
		DEFAULT_UNIT_NAME,
	} from '$lib/constants';

	import * as api from '$lib/api';
	import triggers from '$lib/pluginSystem/triggers';
	import { isEqual } from '$lib/utils/object';
	import { capitalize } from '$lib/utils/string';

	import UnitInput from './UnitInput.svelte';
	import { gridCols, resize, setHeightToMax } from './dom';
	import {
		formatCurrency,
		formatCycle,
		formatPrice,
		getPricing,
		isTiered,
		isUnit,
		isUsage,
		signupUrl,
	} from './prices';

	export let plans: Plan[];
	export let options: PricingOptions = {};

	// a record with the units of each plan belonging to this pricing
	type PricingUnits = Record<Plan['slug'], Units>;

	let totals: Record<Plan['slug'], number> = {};

	function updatePricingUnits(plans: Plan[], cycle: Cycle): PricingUnits {
		pricingUnits ??= {};
		for (const plan of plans) {
			// get units from the signed plan (active contract)
			if (options?.activeContract?.plan === plan.id) {
				pricingUnits[plan.slug] = { ...options.activeContract.units };
				updatePlanTotal(plan);
				continue;
			}
			pricingUnits[plan.slug] = pricingUnits[plan.slug] || {};
			let items = plan.strategy === 'plan' ? [plan] : plan.features;
			for (const item of items) {
				const { pricing_strategy, minimum_units, batch_size } = getPricing(
					item,
					cycle,
					plan.currency
				);

				// do not override the units the user might have entered
				if (isUnit(pricing_strategy) && !pricingUnits[plan.slug][item.slug]) {
					// units are optional, zero is allowed
					// except for for plan level, per unit plan, at least 1 unit
					let defaultMin = 0;
					if (plan.strategy === 'plan' && pricing_strategy === 'per_unit') defaultMin = 1;

					let quantity = minimum_units ?? defaultMin;
					// if default quantity is not multiple of batch_size, we calculate minimum batch greater than minimum units
					if (batch_size && quantity % batch_size !== 0) {
						quantity = (Math.trunc(quantity / batch_size) + 1) * batch_size;
					}
					pricingUnits[plan.slug][item.slug] = quantity;
				}
			}
			updatePlanTotal(plan); // call backend to calculate plan total using current units
		}
		return pricingUnits;
	}

	function updatePlanUnits(plan: Plan, item: Plan | Feature, units: number) {
		pricingUnits[plan.slug][item.slug] = units;
		updatePlanTotal(plan);
	}

	async function updatePlanTotal(plan: Plan) {
		const slug = `${options.wb_customer_slug}/${options.slug}`;
		const estimate = await api.pricing.signupEstimate(slug, {
			currency: plan.currency,
			cycle,
			plan_id: plan.id,
			unit_count_configuration: pricingUnits?.[plan.slug] || {},
		});

		let total = estimate.recurring_price.cycle_start_charges || 0;

		if (plan.discount) {
			const { amount, discount_type } = plan.discount;
			if (discount_type === 'flat') total = total - amount;
			if (discount_type === 'percentage') total = total - (total * amount) / 100;
		}

		totals[plan.slug] = total;
	}

	// get the features of the plan excluding features in previous plans and unit priced features
	function getFeatures(plan: Plan, previous: Plan | undefined, unique_features: boolean) {
		// make sure that every feature of previous is included in current plan
		const skipPrevFeatures =
			unique_features &&
			previous &&
			previous.features.every((p) => plan.features.some((f) => p.id === f.id));

		const unitFeatures =
			plan.strategy === 'plan'
				? []
				: plan.features.filter((feature) =>
						isUnit(getPricing(feature, cycle, plan.currency).pricing_strategy)
				  ); // get features with unit pricing

		// true if there's at least one usage based feature
		const usageFeature =
			plan.strategy === 'plan'
				? false
				: plan.features.some((feature) =>
						isUsage(getPricing(feature, cycle, plan.currency).pricing_strategy)
				  ); // check if there's at least one feature with usage pricing

		// filter unit base features and features in previous plan
		const prev = previous?.features && unique_features ? previous.features : [];
		const features = plan.features.filter(
			(f) =>
				!(
					(skipPrevFeatures && prev.some((p) => p.id === f.id)) || // if skipPrevFeatures filter features from previous plan
					unitFeatures.some((u) => u.id === f.id)
				) // filter unit based features
		);

		return { skipPrevFeatures, unitFeatures, usageFeature, features };
	}

	function getPricePerTier(tiers?: Tier[], quantity = 0, showAdditionalUnitPrice = true): number {
		if (!tiers || !tiers?.length) return 0;
		if (showAdditionalUnitPrice) if (!(tiers.at(-1)!.tier_end === quantity)) quantity++; // display the price of buying ONE MORE unit, not the current ones (unless last one)
		return tiers.find((t) => t.tier_end === 'infinity' || t.tier_end >= quantity)?.amount || 0;
	}

	const slugify = (text: string) =>
		text
			.toLowerCase()
			.replace(/\W+/gi, '_') // replace spaces with _
			.replace(/^_/, '') // remove leading '_'
			.replace(/_$/, ''); // remove trailing '_'

	function getPlanStatus(plan: Plan, cycle: Cycle, currency: Currency, units: PricingUnits) {
		if (!options.activeContract) return 'inactive';

		const current = { plan: plan.id, cycle, currency };
		const currentUnits = units[plan.slug];

		const { units: activeUnits, ...active } = options.activeContract;

		if (!isEqual(active, current)) return 'inactive';
		return isEqual(activeUnits, currentUnits) ? 'active' : 'updated';
	}

	type Pricing = ReturnType<typeof getPricing>;

	function getPriceSummary(price: Pricing, currency: Currency) {
		const amount = formatPrice(price.amount, currency);

		const pricing = price.pricing_strategy;

		if (pricing === 'flat') return `${amount} flat price`;

		const perUnit = `per ${price.unit_name || DEFAULT_UNIT_NAME}`;

		// unit based items, per unit or per usage
		if (!isTiered(pricing)) return `${amount} ${perUnit}`;

		// unit based, tiered (unit_tiered, usage_tiered)
		const tiers = price?.tiers || [];
		// If there are two tiers and the first tier is $0.00 - free tier
		if (tiers.length >= 2 && tiers[0].amount === 0) {
			const tierAmount = formatPrice(tiers[1].amount, currency);

			let included = `${tiers[0].tier_end} included then `;
			// If there are more than two tiers and the first tier is $0.00 - free tier
			if (tiers.length > 2) included += 'starts at ';
			return `${included} ${tierAmount} ${perUnit}`;
		}
		// no free tier
		const tierAmount = formatPrice(tiers?.[0]?.amount, currency);
		return `Starts at ${tierAmount} ${perUnit}`;
	}

	// TRIGGERS
	let phantomRedraw = 0;
	const redraw_trigger = triggers.register.redrawPricingPages(() => {
		console.log('PP: REDRAW');
		phantomRedraw += 1;
	});
	onDestroy(() => {
		redraw_trigger.unregister();
	});

	// set every planPreview header height to match the tallest one in each row
	// so that the price and 'Choose plan' buttons stay vertically aligned
	// #TODO: do it with plain CSS
	let plansEls: HTMLElement[] = [];
	let headersEls: HTMLElement[] = [];
	let discountsEls: HTMLElement[] = [];

	let plansGridCols: number;
	$: if (plansByCycle || plansGridCols || headersEls) {
		setHeightToMax(discountsEls, plansGridCols);
		setHeightToMax(headersEls, plansGridCols);
		setHeightToMax(plansEls, plansGridCols);
	}

	$: pricing_callback_url = options.pricing_callback_url || DEFAULT_PRICING_CALLBACK_URL;
	$: callback = typeof options.callback === 'function' ? options.callback : undefined;
	$: unique_features = options.unique_features ?? true;
	$: className = options.class || '';

	$: decimals = options.decimals ?? DEFAULT_DECIMALS;
	$: if (decimals < 0) decimals = 0;

	$: buyText = options.buyText || '';

	$: theme = options.theme || 'wingback';

	// only cycles in use in one of the plans, sorted according to CYCLES (from smaller to larger)
	$: cycles = CYCLES.filter((cycle) => plans.some((plan) => plan.cycles.includes(cycle)));
	$: cycle = !options.cycle || !cycles.includes(options.cycle) ? cycles[0] : options.cycle;

	$: plansByCycle = plans.filter((plan) => plan.cycles.includes(cycle));

	$: pricingUnits = updatePricingUnits(plansByCycle, cycle);
</script>

<svelte:head>
	{#if theme === 'wingback'}
		<link
			href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
			rel="stylesheet"
		/>
	{:else if theme === 'custom1'}
		<link
			href="https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap"
			rel="stylesheet"
		/>
	{/if}
</svelte:head>

<div
	class:theme-layout={theme !== 'none'}
	class:theme-wingback={theme === 'wingback'}
	class:theme-custom1={theme === 'custom1'}
	class="wb-pricing {className}"
>
	{#key phantomRedraw}
		{#if cycles.length > 1}
			<div class="wb-select-cycles">
				{#each cycles as current}
					<button
						class:selected={cycle === current}
						class="wb-select-cycle"
						on:click={() => (cycle = current)}
					>
						{formatCycle(current)}
					</button>
				{/each}
			</div>
		{/if}

		<div class="wb-plans" use:resize={(el) => (plansGridCols = gridCols(el))}>
			{#each plansByCycle as plan, idx (plan.id)}
				{@const { currency, discount } = plan}
				{@const price = getPricing(plan, cycle, currency)}
				{@const previousPlan = plans[idx - 1]}
				{@const { skipPrevFeatures, unitFeatures, usageFeature, features } = getFeatures(
					plan,
					previousPlan,
					unique_features
				)}
				{@const status = getPlanStatus(plan, cycle, currency, pricingUnits)}
				{@const button =
					// no active contract (signing a new one) and free_trial_days defined
					!options.activeContract && plan.free_trial_days > 0
						? `Start ${plan.free_trial_days}-days free trial`
						: {
								active: buyText || 'Current plan',
								inactive: buyText || 'Choose plan',
								updated: buyText || 'Update plan',
						  }[status]}

				<div class="wb-plan-container" class:wb-has-discount={!!discount}>
					<!-- Discount discount-section -->
					<div class="wb-discount-section" bind:this={discountsEls[idx]}>
						{#if discount}
							<div class="wb-discount">{discount.name}</div>
						{/if}
					</div>

					<div
						class="wb-plan wb-plan-with-discount wb-plan-slug-{plan.slug} wb-plan-name-{slugify(
							plan.name
						)}"
						bind:this={plansEls[idx]}
					>
						<!-- Header title-section -->
						<div class="wb-title-section" bind:this={headersEls[idx]}>
							<div class="wb-title">{plan.external_name || plan.name}</div>
							<div class="wb-description">{plan.description || ''}</div>
						</div>

						<!-- Price -->
						<div class="wb-price-section">
							<span class="wb-price">
								<span class="wb-price-currency-symbol">{formatCurrency(plan.currency)}</span>
								<span class="wb-price-amount">
									{formatPrice(totals[plan.slug] || '', undefined, decimals)}
								</span>
							</span>
							{#if plan.strategy === 'plan' && isUsage(price.pricing_strategy)}
								{@const amount = formatPrice(price.amount, currency, decimals)}
								<span class="wb-billed">
									{amount} per {price.unit_name || DEFAULT_UNIT_NAME}, billed {cycle}ly
								</span>
								{#if price.flat_fee}
									<span class="wb-flat-fee">
										plus {formatPrice(price.flat_fee, currency, decimals)}
										{cycle}ly flat fee
									</span>
								{/if}
							{:else}
								<span class="wb-billed">
									{#if usageFeature}starting price,{/if} billed {cycle}ly
								</span>
							{/if}
						</div>

						<!-- Unit priced plan -->
						{#if plan.strategy === 'plan' && price.pricing_strategy === 'per_unit'}
							{@const unit_name = price.unit_name || DEFAULT_UNIT_NAME}
							<div class="wb-plan-per-unit-section">
								<div class="wb-plan-per-unit-input">
									<UnitInput
										value={pricingUnits[plan.slug][plan.slug]}
										{...price}
										on:change={(e) => updatePlanUnits(plan, plan, e.detail)}
									/>
								</div>
								<div class="wb-plan-per-unit-input-summary">
									{#if !price.flat_fee}
										<span class="wb-plan-per-unit-name">{capitalize(unit_name)}</span>
									{/if}
									<span class="wb-plan-per-unit-price">
										{formatPrice(price.amount, currency, decimals)} per {unit_name.toLowerCase()}
									</span>
									{#if price.flat_fee}
										<span class="wb-plan-per-unit-flat-fee">
											{formatPrice(price.flat_fee, currency, decimals)} flat fee
										</span>
									{/if}
								</div>
							</div>
						{/if}

						<!-- Choose plan button -->
						<div class="wb-buy-section">
							<!-- no callback (javascript or pricing_callback_url defined -->
							{#if !callback && !pricing_callback_url}
								<button class="wb-buy-button disabled">{button}</button>
								<!-- current plan is the active one -->
							{:else if status === 'active'}
								<button class="wb-buy-button disabled">{button}</button>
							{:else}
								<!-- use JavaScript callback or pricing_callback_url -->
								{@const units = pricingUnits[plan.slug] || {}}
								{#await signupUrl(pricing_callback_url, plan, cycle, units, options) then url}
									<!-- JavaScript callback -->
									{#if callback}
										<!-- need to check callback  because ts thinks callback can be undefined -->
										{@const onclick = () =>
											callback && callback({ plan, cycle, currency, units, url })}
										<button class="wb-buy-button" on:click={onclick}>{button}</button>
										<!-- pricing_callback_url -->
									{:else}
										<a class="wb-buy-button" href={url}>{button}</a>
									{/if}
								{/await}
							{/if}
						</div>

						<!-- Unit priced features -->
						{#if plan.strategy === 'feature' && unitFeatures?.length}
							<div class="wb-features-per-unit-section">
								{#each unitFeatures as feature (feature.slug)}
									{@const price = getPricing(feature, cycle, currency)}
									{@const { amount, unit_name, batch_size, pricing_strategy, tiers } = price}
									{@const featureAmount = isTiered(pricing_strategy)
										? getPricePerTier(tiers, pricingUnits[plan.slug][feature.slug])
										: amount}

									{@const each = unit_name ? `per ${unit_name}` : 'each'}
									{@const priceSummary = `${formatPrice(
										featureAmount,
										currency,
										decimals
									)} ${each}`}

									{@const { minimum_units: min, maximum_units: max } = price}
									{@const included = !!min && min === max ? min : null}

									<div class="wb-feature-per-unit">
										<span class="wb-feature-per-unit-name">{feature.name}</span>
										<div class="wb-feature-per-unit-input-container">
											{#if included}
												<div class="wb-feature-per-unit-summary">
													<span class="wb-feature-per-unit-price">
														{included} included {featureAmount > 0
															? ` at ${priceSummary}`
															: ''}</span
													>
												</div>
											{:else}
												<div class="wb-feature-per-unit-input">
													<UnitInput
														value={pricingUnits[plan.slug][feature.slug]}
														{...price}
														on:change={(e) => updatePlanUnits(plan, feature, e.detail)}
													/>
												</div>
												<div class="wb-feature-per-unit-summary">
													{#if unit_name}
														<span class="wb-feature-per-unit-unit-name"
															>{capitalize(unit_name)}</span
														>
													{/if}
													<span class="wb-feature-per-unit-price">{priceSummary}</span>
												</div>
											{/if}
										</div>
										{#if batch_size}
											<span class="wb-feature-per-unit-batch">
												Available in increments of {batch_size}.
											</span>
										{/if}
									</div>
								{/each}
							</div>
						{/if}

						<!-- Features -->
						{#if features}
							<div class="wb-features-section">
								{#if skipPrevFeatures}
									<p class="wb-previous-plan">
										Everything in <span class="wb-previous-plan-name">{previousPlan.name}</span
										>{features.length ? ', plus:' : ''}
									</p>
								{/if}
								<ol class="wb-features">
									{#each features as feature}
										<li class="wb-feature-container wb-feature-slug-{feature.slug}">
											<svg
												class="wb-feature-icon mr-3 hidden self-center fill-emerald-500"
												width="13"
												height="10"
											>
												<path
													d="M12.0458 0.485595C12.3299 0.787026 12.3158 1.26169 12.0144 1.54579L4.08456 9.01968C3.74829 9.33662 3.22042 9.32686 2.89609 8.9977L0.215757 6.27737C-0.074959 5.98231 -0.0714425 5.50745 0.223612 5.21674C0.518666 4.92602 0.993526 4.92954 1.28424 5.22459L3.51839 7.49207L10.9856 0.454214C11.287 0.170114 11.7617 0.184164 12.0458 0.485595Z"
												/>
											</svg>
											<span class="wb-feature">
												<span class="wb-feature-name">{feature.name}</span>
												{#if options.display_prices ?? true}
													{@const price = getPricing(feature, cycle, currency)}
													<!-- price.amount might be zero for tiered prices -->
													{@const isFree = !isTiered(price.pricing_strategy) && price.amount <= 0}
													<!-- do not display flat prices -->
													{@const isFlat = price.pricing_strategy === 'flat'}
													{#if !isFree && !isFlat}
														<span class="wb-feature-price-summary">
															{getPriceSummary(price, currency)}
														</span>
													{/if}
												{/if}
											</span>
										</li>
									{/each}
								</ol>
							</div>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	{/key}
</div>

<style lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	/* Layout theme */

	/* Main container */
	.theme-layout.wb-pricing {
		@apply grid grid-flow-row gap-4 p-4;
	}

	/* Cycle selector */
	.theme-layout .wb-select-cycles {
		@apply grid grid-flow-col justify-center;
	}

	.theme-layout .wb-select-cycle {
		@apply py-2 px-4;
	}

	/* Plans */

	/* grid-cols-[repeat(auto-fit,minmax(280px,350px))]; */
	.theme-layout .wb-plans {
		@apply grid items-stretch justify-center gap-4;
		grid-template-columns: repeat(auto-fit, minmax(280px, 350px));
	}
	.theme-layout .wb-plan {
		@apply grid grid-flow-row content-start gap-4 p-4;
	}

	/* title-section */
	.theme-layout .wb-description {
		@apply overflow-hidden pt-1;
	}

	/* price-section */
	.theme-layout .wb-price-section {
		@apply grid grid-flow-row gap-0;
	}

	/* Unit priced plan  */
	.theme-layout .wb-plan-per-unit-section {
		@apply grid grid-flow-col justify-start gap-2;
	}

	.theme-layout .wb-plan-per-unit-input-summary {
		@apply grid grid-flow-row content-center gap-0;
	}

	/* Choose plan button wb-buy-section */
	.theme-layout .wb-buy-section {
		@apply flex flex-col justify-center;
	}

	/* Unit priced features - wb-features-per-unit-section */
	.theme-layout .wb-features-per-unit-section {
		@apply grid grid-flow-row gap-4;
	}

	.theme-layout .wb-feature-per-unit {
		@apply grid grid-flow-row justify-start gap-2;
	}

	.theme-layout .wb-feature-per-unit-input-container {
		@apply grid grid-flow-col justify-start gap-2;
	}

	.theme-layout .wb-feature-per-unit-summary {
		@apply grid grid-flow-row content-center gap-0;
	}

	/* Features wb-features-section */
	.theme-layout .wb-previous-plan {
		@apply pb-2;
	}

	.theme-layout .wb-features {
		@apply grid grid-flow-row gap-2;
	}

	.theme-layout .wb-feature-container {
		@apply grid grid-cols-[auto,1fr];
	}

	.theme-layout .wb-feature {
		@apply grid grid-flow-row gap-0;
	}

	/* wingback theme */

	/* Main container */
	.theme-wingback.wb-pricing {
		font-family: Inter, sans-serif;
	}

	/* Cycle selector */
	.theme-wingback .wb-select-cycle {
		@apply border border-zinc-200 bg-white text-h200 font-medium text-zinc-500;
	}
	.theme-wingback .wb-select-cycle.selected {
		@apply bg-zinc-50 text-zinc-900;
	}
	.theme-wingback .wb-select-cycle:first-child {
		@apply rounded-l-lg;
	}
	.theme-wingback .wb-select-cycle:last-child {
		@apply rounded-r-lg;
	}

	/* Plans */
	.theme-wingback .wb-discount {
		@apply rounded-t-lg bg-emerald-100 py-1 text-center font-semibold text-emerald-900;
	}

	.theme-wingback .wb-plan {
		@apply rounded-lg border-control;
	}

	.theme-wingback .wb-has-discount .wb-plan {
		@apply rounded-t-none;
	}

	/* title-section */
	.theme-wingback .wb-title {
		@apply text-h300 font-semibold text-zinc-900;
	}

	.theme-wingback .wb-description {
		@apply text-h200 font-normal text-zinc-500;
	}

	/* price-section */
	.theme-wingback .wb-price-section {
		@apply text-h200 font-normal text-zinc-500;
	}

	.theme-wingback .wb-price {
		@apply text-h700 font-semibold text-zinc-900;
	}

	/* Unit priced plan  */
	.theme-wingback .wb-plan-per-unit-input-summary {
		@apply text-h200 font-normal text-zinc-500;
	}

	.theme-wingback .wb-plan-per-unit-name {
		@apply text-zinc-900;
	}

	/* Choose plan button wb-buy-section */
	.theme-wingback .wb-buy-button {
		@apply w-full cursor-pointer rounded-lg py-2 text-center text-h200 font-medium text-zinc-700 shadow-button border-control;
	}
	.theme-wingback span.wb-buy-button.disabled {
		@apply cursor-default opacity-50;
	}

	/* Unit priced features - wb-features-per-unit-section */
	.theme-wingback .wb-features-per-unit-section {
		@apply text-h200 font-normal;
	}
	.theme-wingback .wb-feature-per-unit-name {
		@apply text-zinc-900;
	}
	.theme-wingback .wb-feature-per-unit-summary,
	.theme-wingback .wb-feature-per-unit-batch,
	.theme-wingback .wb-feature-price-summary {
		@apply text-zinc-500;
	}

	/* Features wb-features-section */
	.theme-wingback .wb-features-section {
		@apply text-h200 font-normal text-zinc-900;
	}
	.theme-wingback .wb-previous-plan {
		@apply font-semibold;
	}
	.theme-wingback .wb-feature-icon {
		@apply mr-2 mt-1 block self-start fill-emerald-500;
	}

	/* fogbender theme */

	.theme-custom1.wb-pricing {
		@apply text-gray-700;
		font-family: Archivo, sans-serif;
	}

	/* Cycle selector */
	.theme-custom1 .wb-select-cycle {
		@apply border border-gray-200 py-2 px-4 text-sm font-bold text-gray-600 no-underline;
	}
	.theme-custom1 .wb-select-cycle.selected {
		@apply bg-gray-200;
	}
	.theme-custom1 .wb-select-cycle:first-child {
		@apply rounded-l-lg;
	}
	.theme-custom1 .wb-select-cycle:last-child {
		@apply rounded-r-lg;
	}

	.theme-custom1 .wb-plan {
		@apply flex flex-col gap-0;
	}

	.theme-custom1 .wb-plans {
		@apply grid grid-cols-1 gap-6;
		/* @apply grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4; */
	}
	@media (min-width: 640px) {
		/* @media screen(sm) { */
		.theme-custom1 .wb-plans {
			@apply grid grid-cols-2 gap-6;
		}
	}
	@media (min-width: 1024px) {
		/* @media screen(lg) { */
		.theme-custom1 .wb-plans {
			@apply grid grid-cols-4 gap-6;
		}
	}

	.theme-custom1 .wb-plan {
		@apply mr-4 mt-4 flex w-64 flex-1 flex-col justify-self-center rounded-b border border-gray-200 px-2 pt-0 text-center;
		min-width: 200px;
		max-width: 230px;
	}
	.theme-custom1 .wb-title {
		@apply -mx-2 whitespace-nowrap border-t-8 border-black pt-5 font-bold uppercase text-gray-400;
	}

	/* different upper border colors for plans */
	.theme-custom1 .wb-plan:nth-child(1) .wb-title {
		@apply border-black;
	}
	.theme-custom1 .wb-plan:nth-child(2) .wb-title {
		border-color: rgb(254 52 110); /* border-brand-pink-500 */
	}
	.theme-custom1 .wb-plan:nth-child(3) .wb-title {
		border-color: rgb(255 115 21); /* border-brand-orange-500 */
	}
	.theme-custom1 .wb-plan:nth-child(4) .wb-title {
		border-color: rgb(126 12 245); /* border-brand-purple-500 */
	}
	.theme-custom1 .wb-plan:nth-child(5) .wb-title {
		@apply border-emerald-600;
	}
	.theme-custom1 .wb-plan:nth-child(6) .wb-title {
		@apply border-red-700;
	}

	.theme-custom1 .wb-price {
		@apply my-8 flex place-self-center;
		@apply text-6l font-bold text-gray-900;
	}

	.theme-custom1 .wb-price-currency-symbol {
		@apply place-self-end text-5l;
	}

	.theme-custom1 .wb-description {
		@apply mx-4 mb-4 border-b border-gray-300 pb-4 text-sm text-gray-400;
	}

	.theme-custom1 .wb-billed {
		@apply mx-4 mb-4 border-b border-gray-300 pb-4 text-sm text-gray-400;
	}

	.theme-custom1 .wb-buy-section {
		order: 10; /* send buy button to the bottom */
		@apply mt-8 mb-0;
	}

	.theme-custom1 .wb-buy-button {
		@apply rounded-md bg-gray-200 py-4 px-14 font-bold text-gray-600 no-underline;
	}

	.theme-custom1 .wb-previous-plan {
		@apply pb-4;
	}
	.theme-custom1 .wb-previous-plan-name {
		@apply font-bold;
	}

	.theme-custom1 .wb-features-section {
		@apply flex flex-1 flex-col text-center text-sm;
	}
	.theme-custom1 .wb-features {
		@apply gap-4;
	}
	.theme-custom1 .wb-feature-container {
		@apply place-self-center;
	}
</style>
