// not in core-js at the moment
import ResizeObserver from 'resize-observer-polyfill';

// get the current columns od a responsive html grid
export function gridCols(el: Element, defaultCols = 4) {
	if (!window) return defaultCols;
	const template = window.getComputedStyle(el).gridTemplateColumns;
	return template.split(' ').length;
}

// sets the height of each HTMLElement to the maxHeight of the rest
// grouped by cols
export function setHeightToMax(htmlEls: HTMLElement[], cols?: number): void {
	const els = htmlEls.filter((el) => !!el); // Filter non-null elements

	// Timeout fallback in case no resize event is triggered within 100ms
	let timeoutId: NodeJS.Timeout;

	// Function to set heights
	const adjustHeights = () => {
		clearTimeout(timeoutId); // Clear the fallback timeout if the observer triggers

		cols = cols || els.length;
		for (let i = 0; i < els.length; i += cols) {
			const row = els.slice(i, i + cols);
			const maxHeight = Math.max(...row.map((el) => el.clientHeight));
			row.forEach((el) => (el.style.height = `${maxHeight}px`));
		}

		observer.disconnect(); // Disconnect observer after adjustments
	};

	// Create the observer before setting heights to auto
	const observer = new ResizeObserver(() => {
		adjustHeights();
	});

	// Start observing each element
	els.forEach((el) => observer.observe(el));

	// Clear previously set heights
	for (const el of els) {
		el.style.height = 'auto';
	}

	// Set a timeout to trigger height adjustment if no resize event is fired within 100ms
	timeoutId = setTimeout(() => {
		adjustHeights();
	}, 100);
}

// svelte use:resize action
export function resize(node: HTMLElement, callback: (target: Element) => unknown) {
	const observer = new ResizeObserver(([entry]) => callback(entry.target));
	observer.observe(node);
	return { destroy: () => observer.unobserve(node) };
}
