import * as PluginUtils from '../pluginSystem/utils';
import triggers from '../pluginSystem/triggers';
import type { Plugin } from '../pluginSystem/types';

export class Rewardful implements Plugin {
	name = 'Rewardful';
	rewardfulValue: string | undefined = undefined;
	rewardfulReadySet: boolean = false;

	async init(): Promise<void> {
		// console.log('Rewardful Plugin initialized');
		this.findRewardful();
	}

	findRewardful(): void {
		if (this.rewardfulValue) return;

		// 1. check for Rewardful.referral
		let rewardfulValue = this.getRewardfulValue();
		if (rewardfulValue) {
			this.rewardfulValue = rewardfulValue;
		}

		// 2. Rewardful might still be initializing
		let rewardfulFn = this.getRewardfulFn();
		// only ever add listener for the ready event once
		if (!this.rewardfulReadySet) {
			this.rewardfulReadySet = true;
			if (rewardfulFn) {
				rewardfulFn('ready', () => {
					rewardfulValue = this.getRewardfulValue();
					if (rewardfulValue) {
						// redraw pricing pages, if any, to update the signuplink
						this.rewardfulValue = rewardfulValue;
						triggers.redrawPricingPages();
					}
				});
			}
		}
	}

	getRewardfulValue(): typeof this.rewardfulValue {
		return this.utils._window()?.Rewardful?.referral;
	}
	getRewardfulFn(): Function | undefined {
		return this.utils._window()?.rewardful;
	}

	hooks = {
		createSignupUrl: async (url: URL): Promise<URL> => {
			if (this.rewardfulValue) {
				url.searchParams.set('wb_p[rwdf]', this.rewardfulValue);
			}
			return url;
		},
	};

	utils = PluginUtils;
}
