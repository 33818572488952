import HookManager from './HookManager';
import { systemHooks } from './systemHooks';

// Define the type for the hooks object
type Hooks = {
	[K in keyof typeof systemHooks]: (
		...args: Parameters<typeof systemHooks[K]['execute']>
	) => ReturnType<typeof systemHooks[K]['execute']>;
};

// Create a helper function for dynamically generating each hook
const createHook = <K extends keyof typeof systemHooks>(hookName: K) => {
	return (...args: Parameters<typeof systemHooks[K]['execute']>) => {
		return HookManager.executeHook(hookName, args[0]);
	};
};

// Initialize the hooks object and dynamically populate it
const hooks = Object.keys(systemHooks).reduce((acc, hookName) => {
	const key = hookName as keyof typeof systemHooks;
	acc[key] = createHook(key);
	return acc;
}, {} as Hooks);

// Export the fully typed hooks object
export default hooks;
