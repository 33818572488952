import type {
	Currency,
	Cycle,
	Plan,
	Price,
	Prices,
	PricingOptions,
	PricingStrategy,
	Units,
} from '$lib/types';

import {
	CURRENCY_PREFIXES,
	DEFAULT_DECIMALS,
	DEFAULT_PRICING_CALLBACK_URL,
	MAX_DECIMALS,
} from '$lib/constants';

import hooks from '$lib/pluginSystem/hooks';
import { capitalize } from '$lib/utils/string';

// see https://unicode-explorer.com/c/2009 - a fifth of an em (or sometimes a sixth)
const THIN_SPACE = ' ';

export const formatCycle = (cycle: Cycle) => capitalize(cycle) + 'ly';

export function formatPrice(
	value: string | number,
	currency?: Currency,
	decimals = DEFAULT_DECIMALS
) {
	return (
		(currency ? `${formatCurrency(currency)}${THIN_SPACE}` : '') + // optional currency prefix
		round(value, MAX_DECIMALS).toLocaleString(undefined, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: MAX_DECIMALS,
		})
	);
}

function round(value: number | string, precision = MAX_DECIMALS) {
	const multiplier = 10 ** precision;
	return Math.round((Number(value) || 0) * multiplier) / multiplier;
}

export function formatCurrency(currency: Currency) {
	return CURRENCY_PREFIXES?.[currency] || '$';
}

type PricedItem = {
	prices: Prices;
};

function getPrice(item: PricedItem, cycle: Cycle, currency: Currency): Price {
	const price = item.prices?.[cycle]?.[currency];
	if (!price) {
		const emptyPrice: Price = {
			amount: 0,
			strategy_details: {
				pricing_strategy: 'flat',
			},
		};
		return emptyPrice;
	}
	return price;
}

// convenience function to flatten Price structure, lifting strategy_details props to the root level
export function getPricing(item: PricedItem, cycle: Cycle, currency: Currency) {
	const { amount, tax_code, strategy_details } = getPrice(item, cycle, currency);
	return { amount, tax_code, ...strategy_details };
}

export async function signupUrl(
	pricing_callback_url = '',
	plan: Plan,
	cycle: Cycle | undefined,
	units: Units = {},
	options: PricingOptions = {}
) {
	let template = pricing_callback_url || DEFAULT_PRICING_CALLBACK_URL;
	let templateUrl = new URL(template);

	//check if any parameters were used in the template, otherwise add the default ones
	if (
		!template.match(
			/\{(wb_customer|pricing|plan|plan_id|pricing_id|slug|cycle|currency|callback)\}/i
		)
	) {
		const default_params = {
			//pricing: '{wb_customer}/{pricing}',
			//plan: '{wb_customer}/{slug}',
			pricing: '{pricing_id}',
			plan: '{plan_id}',
			cycle: '{cycle}',
			currency: '{currency}',
			callback: '{callback}',
		};

		Object.entries(default_params).forEach(([key, value]) =>
			templateUrl.searchParams.set(key, value)
		);
	}

	// add units
	Object.entries(units).forEach(([slug, amount]) =>
		templateUrl.searchParams.set(slug, amount.toString())
	);

	if (!cycle) cycle = plan.cycles[0] || 'year';

	// iterate through every param and replace the template parameters
	// not very efficient, but at least correct
	templateUrl.searchParams.forEach((value, key) => {
		templateUrl.searchParams.set(
			key,
			value
				.replace(/{WB_CUSTOMER}/gi, options.wb_customer_slug || '')
				.replace(/{PRICING}/gi, options.slug || '')
				.replace(/{PLAN}/gi, plan.name)
				.replace(/{PLAN_ID}/gi, plan.id)
				.replace(/{PRICING_ID}/gi, options.id || '')
				.replace(/{SLUG}/gi, plan.slug)
				.replace(/{CYCLE}/gi, cycle)
				.replace(/{CURRENCY}/gi, plan.currency)
				.replace(/{CALLBACK}/gi, options.signup_callback_url || '')
		);
	});

	// run plugin hook for any additional modifications, this passes url as reference
	templateUrl = await hooks.createSignupUrl(templateUrl);

	// remove empty params
	templateUrl.searchParams.forEach((value, key) => {
		if (value === '') {
			templateUrl.searchParams.delete(key);
		}
	});

	//console.log('new url', await hooks.createSignupUrl(url.toString()));
	return templateUrl.toString();
}

export const isUsage = (pricing: PricingStrategy) => ['usage', 'usage_tiered'].includes(pricing);
export const isUnit = (pricing: PricingStrategy) => ['per_unit', 'unit_tiered'].includes(pricing);
export const isTiered = (pricing: PricingStrategy) =>
	['usage_tiered', 'unit_tiered'].includes(pricing);
