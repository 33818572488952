import type * as Types from '$lib/types';

import * as api from '$lib/api';

import { Pricing } from '$lib/components';

export const fetch = async (slugOrId: string) => {
	try {
		return await api.pricing.get(slugOrId);
	} catch (e) {
		throw new Error(`pricing with slug or id "${slugOrId}" not found`);
	}
};

export const render = async (
	el: HTMLElement | string,
	slugOrPricing: string | Types.Pricing,
	options: Types.PricingOptions = {}
) => {
	if (!slugOrPricing) throw new Error('must specify the slug or pricing to render');

	if (typeof slugOrPricing === 'string' && !slugOrPricing.match(/^Pri_|\w+\/\w+$/gi)) {
		throw new Error(
			'invalid pricing slug specified. Valid format: "wb_customer_slug/pricing_slug" or "Pri_"'
		);
	}

	const pricing = typeof slugOrPricing === 'string' ? await fetch(slugOrPricing) : slugOrPricing;

	const {
		wb_customer_slug,
		wb_customer_id,
		id,
		slug,
		plans,
		pricing_callback_url,
		signup_callback_url,
		unique_features,
		display_prices,
	} = pricing;

	// override plan configuration with options
	const opt = {
		wb_customer_slug,
		wb_customer_id,
		id,
		slug,
		pricing_callback_url,
		signup_callback_url,
		unique_features,
		display_prices,
		...options,
	};

	renderPlans(el, plans, opt);
};

export const renderPlans = (
	el: HTMLElement | string,
	plans: Types.Plan[],
	options: Types.PricingOptions
) => {
	if (!el) throw new Error('must specify html element to render pricing');

	if (typeof el === 'string') {
		const tmpEl = document.getElementById(el) || document.querySelector(el);
		if (!tmpEl) throw new Error(`could not find HTMLElement by css selector ${el}`);
		el = tmpEl;
	}

	if (options.callback && typeof options.callback !== 'function')
		throw new Error('invalid callback parameter specified. callback must be a function.');

	el.replaceChildren(); // clear target
	new Pricing({
		target: el,
		props: { plans, options },
	});
};
