import type { systemHooks } from './systemHooks';

export enum ExecutionType {
	NonBlocking = 'non-blocking',
	BlockingIterative = 'blocking-iterative',
	BlockingParallel = 'blocking-parallel',
}

// Non-blocking: No defaultFn, no result expected
export type NonBlocking<TArgs = void> = {
	executionType: ExecutionType.NonBlocking;
	execute: (args: TArgs) => void;
};

// Blocking-Iterative: Requires defaultFn, conditionally requires initialValue if TArgs is void
export type BlockingIterative<TArgs, TResult> = {
	executionType: ExecutionType.BlockingIterative;
	execute: (args: TArgs) => Promise<TResult>;
	defaultFn: TArgs extends void ? () => TResult : (args: TArgs) => TResult;
	initialValue?: TArgs extends void ? TResult : TResult | undefined;
};

// Blocking-Parallel: No defaultFn, returns array of results
export type BlockingParallel<TArgs, TResult> = {
	executionType: ExecutionType.BlockingParallel;
	execute: (args: TArgs) => Promise<TResult[]>;
};

// Helper functions to create hooks/triggers
export function nonBlocking<TArgs = void>(): NonBlocking<TArgs> {
	return {
		executionType: ExecutionType.NonBlocking,
		execute: (args: TArgs) => {},
	};
}

export function blockingIterative<TArgs, TResult>(
	defaultFn: TArgs extends void ? () => TResult : (args: TArgs) => TResult,
	initialValue?: TArgs extends void ? TResult : TResult | undefined
): BlockingIterative<TArgs, TResult> {
	return {
		executionType: ExecutionType.BlockingIterative,
		execute: async (args: TArgs) => defaultFn(args),
		defaultFn,
		initialValue,
	};
}

export function blockingParallel<TArgs, TResult>(): BlockingParallel<TArgs, TResult> {
	return {
		executionType: ExecutionType.BlockingParallel,
		execute: async (args: TArgs) => [],
	};
}

// Define Hook and Trigger Types
export type HookDefinition<TArgs = void, TResult = void> =
	| NonBlocking<TArgs>
	| BlockingIterative<TArgs, TResult>
	| BlockingParallel<TArgs, TResult>;

export type TriggerDefinition<TArgs = void, TResult = void> =
	| NonBlocking<TArgs>
	| BlockingIterative<TArgs, TResult>
	| BlockingParallel<TArgs, TResult>;

export interface Plugin {
	name: string;
	init(): Promise<void>;
	hooks?: Partial<{
		[K in keyof typeof systemHooks]: (
			args: Parameters<typeof systemHooks[K]['execute']>[0]
		) => ReturnType<typeof systemHooks[K]['execute']>;
	}>;
}
