import triggers from '../pluginSystem/triggers';
import type { Plugin } from '../pluginSystem/types';

export class ExamplePlugin implements Plugin {
	name = 'ExamplePlugin';

	async init(): Promise<void> {
		// console.log('ExamplePlugin initialized');
	}

	hooks = {
		createSignupUrl: async (url: URL): Promise<URL> => {
			// url.searchParams.set('plugin', 'example');
			return url;
		},
	};

	async performCustomAction(cost: { total: number; currency: string }): Promise<void> {
		// Use the redrawPricingPage trigger (non-blocking)
		await triggers.redrawPricingPages();
		console.log('Requested pricing page redraw');

		// Use the sumCost trigger (blocking-iterative)
		const updatedCost = await triggers.sumCost({ total: 123, currency: 'USD' });
		console.log('Updated cost after sumCost trigger:', updatedCost);

		// Use the getAllPricingSlugs trigger (blocking-parallel)
		const slugs = await triggers.getAllPricingSlugs();
		console.log('All pricing slugs:', slugs);
	}
}
