import type { CURRENCIES, CYCLES } from '$lib/constants';

export const EMPTY_ADDRESS: Address = {
	holder_name: '',
	line_1: '',
	line_2: '',
	city: '',
	zip: '',
	state: '',
	country: '',
};

export type Address = {
	holder_name: string;
	line_1: string;
	line_2: string;
	city: string;
	zip: string;
	state: string;
	country: string;
};

// information needed by the frontend to
// instantiate stripe library on behalf of our wb_customer: const stripe = Stripe(pk_key,{ connected_account_id });
// allow the user to enter the credit card information in a secure way: stripe.elements()
// complete the stripe setup intent created by the server: stripe.confirmCardSetup(client_secret, ...
export type StripeAuth = {
	pk_key: string;
	connected_account_id: string;
	client_secret: string;
};

// returned by POST /c/customer/${customer}/payment/session
// returned by GET /p/session
export type PaymentSession = {
	token: string;
	wb_customer_id: string;
	customer_id: string;
	provider: 'stripe';
	operation: 'add_method';
	status: 'pending';
	address: boolean;
	detach_previous_on_success: boolean;
	setup_intent: {
		wb_customer_id: string;
		customer_id: string;
		address: true;
		stripe: StripeAuth;
	};
};

export type UpdateSessionStatus = {
	status: 'complete' | 'error';
	holder_name: string;
};

// returned by POST /p/session { status: 'complete' }
// for operation 'add_method'
export type AddMethodSuccess = {
	id: string;
	provider: 'stripe'; // only stripe currently
	card_label?: string;
	// see: https://stripe.com/docs/testing#cards
	card_brand: 'visa' | 'mastercard' | 'amex' | 'discover' | 'diners' | 'jcb' | 'unionpay';
	card_last_digits: string;
	card_expiration: string;
	card_bin: string;
	status: string; // 'ok' | '?'
	preference: string;
	created: string;
};

// dispatched by PaymentForm.change event
export type PaymentChangeEvent = {
	empty: boolean;
	complete: boolean;
};

// Pricing
export type Pricing = {
	wb_customer_slug: string;
	wb_customer_id: `WCust_${string}`;
	id: `Pri_${string}`;
	slug: string;
	name: string;
	description?: string;
	currencies: Currency[];
	cycles: Cycle[];
	pricing_callback_url: string;
	signup_callback_url: string;
	unique_features: boolean;
	display_prices: boolean;
	plans: Plan[];
	use_account_service: Permission;
};

export type Cycle = typeof CYCLES[number];
export type Currency = typeof CURRENCIES[number];
export type PricingLevel = 'feature' | 'plan';
export type PricingStrategy = 'flat' | 'per_unit' | 'unit_tiered' | 'usage' | 'usage_tiered';

export type Plan = {
	id: string;
	slug: string;
	name: string;
	internal_name: string;
	external_name: string | null;
	description: string;
	status: 'draft' | 'published' | 'in_use' | 'archived';
	currency: Currency;
	cycles: Cycle[];
	strategy: PricingLevel;
	prices: Prices;
	features: Feature[];
	discount: Discount | undefined | null;
	free_trial_days: number;
};

export type Price = {
	amount: number;
	tax_code?: string;
	strategy_details: {
		pricing_strategy: PricingStrategy;
		unit_name?: string | null;
		batch_size?: number | null;
		flat_fee?: number | null;
		minimum_units?: number | null;
		maximum_units?: number | null;
		tiers?: Tier[];
	};
};

export type Tier = {
	tier_start: number;
	tier_end: number | 'infinity';
	amount: number;
};

export type Prices = {
	[key in Cycle]?: {
		[key in Currency]?: Price;
	};
};

export type Feature = {
	id: string;
	slug: string;
	name: string;
	tags: Tag[];
	prices: Prices;
};

export type Tag = {
	id: string;
	name: string;
};

export type Discount = {
	id: `Disc_${string}`;
	name: string;
	slug: string;
	discount_type: 'percentage' | 'flat';
	amount: number;
};

// Pricing component types

export type ContractSignature = {
	plan: string;
	cycle: Cycle;
	currency: Currency;
	units?: Units;
};
export type PricingOptions = {
	wb_customer_slug?: string; // e.g. WINGBACK
	wb_customer_id?: `WCust_${string}`;
	slug?: string; // e.g. MAIN_PRICING, complete pricing page slug = WINGBACK/MAIN_PRICING
	id?: `Pri_${string}`;
	callback?: CallbackFn;
	pricing_callback_url?: string;
	signup_callback_url?: string;
	unique_features?: boolean;
	display_prices?: boolean;
	activeContract?: ContractSignature;
	cycle?: Cycle;
	class?: string;
	theme?: 'wingback' | 'custom1' | 'layout' | 'none';
	decimals?: number;
	buyText?: string;
};
export type Units = Record<Feature['slug'] | Plan['slug'], number>;

export type CallbackFn = (params: {
	plan: Plan;
	cycle: Cycle;
	currency: Currency;
	units?: Units;
	url?: string;
}) => void;

type Permission = 'yes' | 'no' | 'inherit' | 'no_but_overridable';
