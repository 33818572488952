import * as Wingback from './modules';
import HookManager from './pluginSystem/HookManager';
import { defaultPlugins } from './plugins';

// Initialize default plugins
HookManager.registerPlugins(defaultPlugins);

// Export only the necessary parts (no hooks or triggers exposed)
export default {
	...Wingback,
	registerPlugin: HookManager.registerPlugin.bind(HookManager),
};
