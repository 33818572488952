import TriggerManager from './TriggerManager';
import { pluginTriggers } from './pluginTriggers';
import { type HookDefinition } from './types';

// PluginTriggers is the type of all triggers in pluginTriggers.ts
type PluginTriggers = typeof pluginTriggers;

// Extracts the hook arguments and result types from the HookDefinition
type ExtractHookTypes<T extends HookDefinition<any, any>> = T extends HookDefinition<
	infer Args,
	infer Result
>
	? [Args, Result]
	: never;

// Define the type of the trigger function
type TriggerFunction<K extends keyof PluginTriggers> = (
	...args: ExtractHookTypes<PluginTriggers[K]>[0] extends void
		? []
		: [ExtractHookTypes<PluginTriggers[K]>[0]]
) => Promise<ExtractHookTypes<PluginTriggers[K]>[1]>;

// Define the type of the register function
type RegisterFunction<K extends keyof PluginTriggers> = (
	callback: (
		args: ExtractHookTypes<PluginTriggers[K]>[0]
	) => ExtractHookTypes<PluginTriggers[K]>[1] | Promise<ExtractHookTypes<PluginTriggers[K]>[1]>
) => { unregister: () => void };

// Define the type for the triggers object, including both call and register functionality
type Triggers = {
	[K in keyof PluginTriggers]: TriggerFunction<K>;
} & {
	register: {
		[K in keyof PluginTriggers]: RegisterFunction<K>;
	};
};

// Create a helper function for trigger creation
const createTrigger = <K extends keyof PluginTriggers>(triggerName: K) => {
	return async (arg?: any) => {
		return TriggerManager.executeTrigger(triggerName, arg);
	};
};

// Create a helper function for register creation
const createRegister = <K extends keyof PluginTriggers>(triggerName: K) => {
	return (callback: any) => {
		return TriggerManager.register(triggerName, callback);
	};
};

// Dynamically create the triggers object with proper typing
const triggers = Object.keys(pluginTriggers).reduce(
	(acc, key) => {
		const triggerKey = key as keyof PluginTriggers;

		// Populate the trigger functions
		acc[triggerKey] = createTrigger(triggerKey);

		// Populate the register functions
		acc.register[triggerKey] = createRegister(triggerKey);

		return acc;
	},
	{ register: {} } as Triggers
);

// Export the fully typed triggers object
export default triggers;
